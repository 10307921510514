@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    font-family: 'Open Sans', sans-serif;
}
.active{
    @apply text-second
}
.dropdown{
    animation-name: dropdown;
    animation-duration: 0.6s;
}
@keyframes dropdown {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a80000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
  